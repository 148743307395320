/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import { TTableRow, TTableHeader, TDetailsCell, Comparison, TTableConfig, TWidgetSchema } from '../types';
import { PublishPayload } from '@netvision/lib-types-frontend';
import { useWindowSize } from '../hooks/useWindowSize';
import { useLocale } from '../hooks/useLocale';
import { useSchema } from '../hooks/useSchema';
import { useData } from '../hooks/useData';
import { useObserver } from '../hooks/useObserver';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { EmptySection } from './EmptySection';
import { ScrollPanel } from 'primereact/scrollpanel';
import { get } from 'lodash-es';

type TTableProps = {
  selectedRows: string[];
  isSelectable: boolean;
  isRouterEnabled: boolean;
  detailsCell?: TDetailsCell;
  selectUnselectAllRows: () => void;
  selectUnselectSingleRow: (id: string) => void;
  openDetails?: (entity: TTableRow, comparison?: Record<string, Comparison>) => void;
}

export const Table = ({
  selectedRows,
  isSelectable,
  isRouterEnabled,
  detailsCell,
  selectUnselectAllRows,
  selectUnselectSingleRow,
  openDetails,
}: TTableProps) => {
  const { $t } = useLocale();
  const [firstColIndex, setFirstColIndex] = useState(0);
  const [colsQuantity, setColsQuantity] = useState(4);
  const [isSwipable, setIsSwipable] = useState(false);
  const [openedRow, setOpenedRow] = useState<TTableRow | null>(null);
  const { winWidth } = useWindowSize();
  const { eventBus, eventBusID } = useObserver();
  const { schema, setSchema } = useSchema();
  const { data, dataMap } = useData();
  const detailsRef = useRef<HTMLDivElement | null>(null);
  
  const accessibleCols = useMemo(() => {
    return schema?.viewTable.order
      .filter((key) => !schema.viewTable.columns[key]?.hidden) || []
  }, [schema?.viewTable.order, schema?.viewTable.columns]);
  
  const dynamicOrder = useMemo(() => {
    const permanentCols = new Set([
      ...Object.entries(schema.viewTable.columns)
        .reduce<string[]>((acc, [cellKey, { permanent }]) => {
          permanent && acc.push(cellKey);
          return acc;
        }, []),
      ...(schema.viewTable.groups
        ?.reduce<string[]>((acc, { field, isActive }) => {
          isActive && acc.push(field)
          return acc
        }, []) || [])
    ]);

    const orderResult = accessibleCols.reduce<string[]>((acc, next, index, arr) => {   
      if (index < colsQuantity) {
          if (permanentCols.has(next)) {
              acc.push(next);
        } else {
          if (!arr[firstColIndex + index]) {
            setFirstColIndex(0)
          }

          acc.push(arr[firstColIndex + index]);
        }
      }
      return acc;
    }, []);

    return [...new Set(orderResult)];
  }, [accessibleCols, colsQuantity, firstColIndex, schema]);

  const dynamicHeader = useMemo(() => (
    dynamicOrder.reduce<TTableHeader[]>((acc, next) => {
      if (Reflect.has(schema.viewTable.columns, next)) {
        acc.push({
          title: $t(next) || next,
          schema: schema.viewTable.columns[next],
          columnKey: next
        });
      }

      return acc;
    }, [])
  ), [schema?.viewTable, dynamicOrder, colsQuantity]);

  const dynamicBody = useMemo(() => {
    return data.map((row) => (
      dynamicOrder.reduce<TTableRow>((acc, key) => {
        acc[key] = get(row, key)
        acc.id = row.id
        return acc
      }, {})
    ))
  }, [data, dynamicOrder, colsQuantity]);

  const detailsOpen = (rowID: string) => {
    setOpenedRow(dataMap.get(rowID) || null);
  }

  const updateSettingsState = <T extends TTableConfig & Pick<TWidgetSchema, 'isGrouped'>>(payload: PublishPayload<T>) => {
    setSchema({
      ...schema,
      isGrouped: Boolean(payload.data.isGrouped),
      viewTable: {
        columns: payload.data.columns,
        order: payload.data.order,
        groups: payload.data.groups
      }
    });
  }

  useEffect(() => {
    if (winWidth < 520) {
      setColsQuantity(2);
      setFirstColIndex(0);
    } else if (winWidth >= 520 && winWidth < 768) {
      setColsQuantity(3);
      setFirstColIndex(0);
    } else if (winWidth >= 768 && winWidth < 1024) {
      setColsQuantity(4);
      setFirstColIndex(0);
    } else if (winWidth >= 1024 && winWidth < 1200) {
      setColsQuantity(4);
      setFirstColIndex(0);
    } else if (winWidth >= 1200 && winWidth < 1400) {
      setColsQuantity(5);
      setFirstColIndex(0);
    } else if (winWidth >= 1440 && winWidth < 1920) {
      setColsQuantity(6);
      setFirstColIndex(0);
    } else if (winWidth >= 1920 && winWidth < 2048) {
      setColsQuantity(7);
      setFirstColIndex(0);
    } else if (winWidth >= 2048 && winWidth < 2560) {
      setColsQuantity(8);
      setFirstColIndex(0);
    } else {
      setColsQuantity(accessibleCols.length);
      setFirstColIndex(0);
    }
  }, [winWidth, accessibleCols]);

  useEffect(() => {
    setIsSwipable(dynamicOrder.length < accessibleCols.length);
  }, [dynamicOrder, accessibleCols]);
  
  useEffect(() => {
    if (openedRow && openDetails) {
      openDetails(openedRow)
      setOpenedRow(null);
    }
  }, [openedRow, data, openDetails]);

  useEffect(() => {
    if (eventBusID) {
      // @ts-ignore
      eventBusID && eventBus.subscribe(eventBusID, 'config:changed', updateSettingsState);
    }

    return () => {
      // @ts-ignore
      eventBusID && eventBus.unsubscribe(eventBusID, 'config:changed', updateSettingsState);
    }
  }, []);

  return (
    <Fragment>
      <table css={tableCSS} className="layout-fixed wtable">
        <TableHead
          columns={dynamicHeader}
          isSwipable={isSwipable}
          isSelectable={isSelectable}
          firstColIndex={firstColIndex}
          lastColIndex={accessibleCols.length}
          isRouterEnabled={isRouterEnabled}
          updateFirstColIndex={(index) => setFirstColIndex(index)}
          selectUnselectAllRows={selectUnselectAllRows}
          isChecked={selectedRows.length === data.length}
        />
      </table>
      {!data.length
        ? <EmptySection />
        : <ScrollPanel style={{ overflow: 'hidden' }}>
          <table css={tableCSS} className="layout-fixed wtable">
              <TableBody
                order={dynamicOrder}
                columns={schema?.viewTable.columns}
                isSwipable={isSwipable}
                isSelectable={isSelectable}
                detailsCell={detailsCell}
                rows={dynamicBody}
                selectedRows={selectedRows}
                selectUnselectRow={selectUnselectSingleRow}
                detailsOpen={detailsOpen}
              />
          </table>
        </ScrollPanel>
      }
      <div ref={detailsRef} />
    </Fragment>
  )
}

const tableCSS = css`
  width: 100%;
  border-collapse: collapse;
  font-size: calc(16rem / var(--bfs));

  &.layout-fixed {
    table-layout: fixed;
  }

  .wtable__cell {
    padding-top: calc(20rem / var(--bfs));
    padding-bottom: calc(20rem / var(--bfs));
    word-break: break-word;

    &:not(:first-of-type) {
      padding-left: calc(30rem / var(--bfs));
    }
  }

  .wtable__body {
    .wtable__row {
      transition: background-color var(--transition-duration), color var(--transition-duration), border-color var(--transition-duration), box-shadow var(--transition-duration);
      outline-color: rgba(var(--primary-color-raw), 0.5);

      &:hover {
        background: rgba(var(--primary-color-raw), 0.05) var(--selection-gradient);
        color: var(--text-color);
      }
    }
  }
`
